import { template as template_9375dab45b754531a99d77f4c550c1c6 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9375dab45b754531a99d77f4c550c1c6(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
